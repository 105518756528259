'use strict';

(function () {
  var _controller = 'newTurn.controller';
  angular.module('pentaApp').controller(_controller, controller);
  controller.$inject = ['$scope', '$q', 'router.resource', 'reservation.resource', 'additionalInformation.resource',
    'queue.resource', '$timeout', 'holiday.resource', 'reservationStats.resource', 'posTable.resource', 'appRate.modal', 'geolocation.factory', 'reservation.factory',
    'manualGeolocation.modal', 'queueAttending.resource', 'waitingList.resource', 'noTurnsAvailable.modal', 'joinWaitlist.modal'];
  function controller($scope, $q, routerResource, reservationResource, additionalInformationResource,
    queueResource, $timeout, holidayResource, reservationStats, posTableResource, appRateModal, geolocationFactory, reservationFactory,
    manualGeolocationModal, queueAttendingResource, waitingListResource, noTurnsAvailableModal, joinWaitlistModal) {
    //// Methods
    $scope.setStep = setStep;
    $scope.nextStep = nextStep;
    $scope.nextStepCheck = nextStepCheck;
    $scope.prevStep = prevStep;
    $scope.selectQueue = selectQueue;
    $scope.takeTurn = takeTurn;
    $scope.modifyTurn = modifyTurn
    $scope.finishReservation = finishReservation;
    $scope.getSelectedQueuesNames = getSelectedQueuesNames;
    $scope.limitRoutersOnManualPosition = limitRoutersOnManualPosition;
    $scope.aiCallbacks = {}; // Aqui adentro van los callbacks del componente de informacción adicional, se usa un objeto para evitar peligrosas referencias con $parent
    $scope.dateToAvailability = dateToAvailability
    $scope.joinWaitlist = joinWaitlist;
    //// VARS

    if (profile.enterprise && profile.enterprise.pwaConfig && profile.enterprise.pwaConfig.turnsConfig) {
      $scope.hideQueue = profile.enterprise.pwaConfig.turnsConfig.hideQueue;
      var manualRouter = profile.enterprise.pwaConfig.turnsConfig.manualRouter;
    }
    var queryStringRouter = window.queryStrings && window.queryStrings.router;
    var defaultRouter = appNavigator.topPage.data.currentRouter;
    var turnToModify = appNavigator.topPage.data.turnToModify // Para autocompletar los datos de un turno que estoy modificando
    $scope.modifying = false // bandera usada en el jade para saber si estoy modificando el turno
    $scope.autoComplet = false // bandera para saber si tengo que autocompletar los datos cuando estoy modificando
    if (turnToModify && turnToModify.date && !turnToModify.date.getTime) turnToModify.date = new Date(turnToModify.date)
    var allRouters = null; // allRouters contiene todas las sucursales ya filtradas por ubicación si es que estuviera activado el filtro por rango de distancia.
    var queueTree;
    let holidays = holidayResource.query({ _controller: _controller, "applyFor.turns": true }, function (data) { })
    $scope.routers = null; // Estas son las sucursales que el usuario puede elegir, ya filtrada por ubicación y por motivo.
    $scope.reservation = { inmediate: null };
    $scope.step = null;
    $scope.selectedQueues = []; // Motivos seleccionados . stepMode = 'MULTI-QUEUE-*'.
    $scope.selectedQueue = null; // Motivo seleccionado
    $scope.selectedRouter = null; // Sucursal seleccionada
    $scope.queueTree = null; // Para armar el arbol de motivos
    $scope.additionalInformation = null; // Si el motivo seleccionado requiere completar informacion adicional
    $scope.availability = null; // Para armar el listado de dias para turnos futuros
    $scope.selectedDay = null; // Día actual seleccionado para turno futuro
    $scope.rightNow = null; // Para ocultar cualquier hora que sea anterior a ahora mismo
    $scope.stepMode = profile.enterprise && profile.enterprise.pwaConfig && profile.enterprise.pwaConfig.turnsConfig && profile.enterprise.pwaConfig.turnsConfig.stepMode || null;
    var onClose = appNavigator.topPage.data && appNavigator.topPage.data.onClose;
    var reservationConfig = null; // Configuración de turnos del reouter
    var posTables = null; // En caso que el modo sea calendarizado por locación
    var routerReservations = null; // Para el calculo de disponibilidad
    var additionalInformations = null;
    var steps = ['router', 'queue', 'slots', 'reservationType', 'day', 'date', 'additional', 'comment', 'confirm'];

    $scope.AttachedImgFilesConfig = { multiple: true, accept: "'image/*'", maxSize: '10MB', capture: "'camera'" };
    $scope.AttachedAllFilesConfig = { multiple: true, maxSize: '10MB', capture: "'camera'" };

    var additionalInformationProfile = $scope.$root.profile && $scope.$root.profile.enterprise && $scope.$root.profile.enterprise.additionalInformation || [];
    $scope.maxRouterDistance = profile.enterprise && profile.enterprise.pwaConfig && profile.enterprise.pwaConfig.turnsConfig && profile.enterprise.pwaConfig.turnsConfig.maxRouterDistance
    $scope.routersReady = false
    $scope.outOfRange = false
    $scope.userPosition;
    $scope.availablesDays = null
    var notAttending = false
    $scope.myWaitingList = [] // Array que contiene mi lista de espera
    // Init
    init()

    // WATCHERS
    $scope.$on('$destroy', onDestroy)
    $scope.$watch('queues.length', (newVal, oldVal) => {
      if (newVal === oldVal) return
      if ($scope.queues.some(f => f.activeWaitingList))
        getMyWaitingList()
    })
    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }

    // Implementación
    function init() {
      routerResource.query({ _controller: _controller }, function (result) {
        allRouters = result.filter(function (f) { return !f.hidden });
        $scope.unfilteredRouters = allRouters // unfilteredRouters se usa para obtener todos los routers a la hora de filtrarlos manualmente (una vez que el filtro automático falló).
        additionalInformations = additionalInformationResource.query({ targets: 'QUEUE', moments: 'PRE', _controller: _controller });
        $q.all([filterRoutersByDistance(allRouters), additionalInformations.$promise])
          .then(function (results) {
            allRouters = results[0];
            $scope.routers = allRouters;
            $scope.routersReady = true
            $scope.routersDistanceReady = true;
            if ($scope.maxRouterDistance && !allRouters.length) {
              $scope.outOfRange = true
              if (!$scope.userPosition) $scope.humanizedPosition = false
              else {
                displayLocation($scope.userPosition.latitude, $scope.userPosition.longitude).then(function (humanizedPosition) {
                  $scope.humanizedPosition = humanizedPosition
                }).catch(function (err) { throw new PentaError(err) })
              }
            }
            if (turnToModify) {
              $scope.modifying = true
              $scope.autoComplet = true
              $scope.reservation = angular.copy(turnToModify)
              delete $scope.reservation._id
            }
            switch ($scope.stepMode) {
              case 'MULTI-QUEUE-FIRST':
                steps.unshift('multiQueueFirst');
                queueResource.query({ enterprise: profile.enterprise._id, _controller: _controller }, function (data) {
                  //voy a filtrar todos los motivos que sean pagos, no está implementado motivos pagos para multi turnos
                  data = data.filter(f => f.routers && !f.routers.find(r => r.payConfig && r.payConfig.enabled))
                  data.forEach(function (item) { buildChilds(item, data) });
                  queueTree = data.filter(function (f) { return !f.parent });
                  $scope.queueTree = queueTree;
                  $scope.step = steps[0];
                  if ($scope.autoComplet) {
                    if (turnToModify._childrens.length)
                      turnToModify._childrens.forEach(function (item) {
                        var queue = data.find(function (f) { if (f._id === item.queue) { return f } })
                        if (queue) selectQueue(queue)
                      })
                    else {
                      var queue = data.find(function (f) { if (f._id === turnToModify.queue) { return f } })
                      if (queue) selectQueue(queue)
                    }
                    nextStep(true)
                  }
                  else if (data.length === 1) nextStep(data[0]);
                });
                break;
              default:
                $scope.step = steps[0];
                if ($scope.autoComplet) nextStep(turnToModify.router)
                else if (!manualRouter || queryStringRouter) {
                  if ($scope.routers.find(function (f) { return f._id === defaultRouter._id })) nextStep(defaultRouter._id); // Elijo por defecto el router si es necesario.
                }
                break;
            }
          })
          .catch(function (err) { if (err) throw new PentaError(err) })
      });
    }

    function setStep(stepName) {
      $scope.step = stepName;
      // Reseteo los pasos posteriores
      for (var stepIx = steps.indexOf(stepName) + 1; stepIx < steps.length; stepIx++) {
        nextStep(null, steps[stepIx])
      }
      // Reseteo turnsToModify porq si cambia de step ya no tengo q autocompletar los datos
      $scope.autoComplet = false
    }

    // solo se usa en el caso stepValue = INMEDIATE
    function nextStepCheck(stepValue) {
      notAttending = false
      if (stepValue !== 'INMEDIATE') return
      if ($scope.selectedQueue.router && $scope.selectedQueue.router.immediateOnlyIfAttending)
        queueAttendingResource.get({ enterprise: profile.enterprise._id, router: $scope.selectedRouter._id, queue: $scope.selectedQueue._id, _controller: _controller }, function (data) {
          notAttending = data.notAttending
          if (notAttending) {
            $scope.disableInmediaTurns = true
            ons.notification.alert({ message: i18next.t('No hay agentes para atender en forma inmediata, agende un turno '), title: i18next.t('Atención') });
            setStep('reservationType')
          } else
            nextStep(stepValue);
        })
      else
        nextStep(stepValue);
    }

    function nextStep(stepValue, stepName) {
      // Procesar el valor actual
      var step = stepName || $scope.step;
      switch (step) {
        case 'router':
          if (stepValue) {
            $scope.selectedRouter = $scope.routers.find(function (f) { return f._id === stepValue });
            if (!$scope.selectedRouter) throw new PentaError('Sucursal inválida')
            $scope.routerStats = reservationStats.get({ router: stepValue });
            $scope.reservation.router = stepValue;
            $scope.selectedQueues.forEach(function (queue) { queue.router = queue.routers && queue.routers.length && queue.routers.find(function (f) { return f._id === $scope.reservation.router }); })
            //$scope.queueTree = null;
          } else {
            $scope.selectedRouter = null;
            $scope.reservation.router = null;
          }
          break;
        case 'queue':
          if (stepValue && $scope.stepMode !== 'MULTI-QUEUE-FIRST') {
            $scope.selectedQueue = stepValue;
            $scope.reservation.queue = stepValue && stepValue._id;
            $scope.reservation._payment = stepValue.router && stepValue.router.payConfig && stepValue.router.payConfig.enabled

            notAttending = false
            if ($scope.selectedQueue.router && $scope.selectedQueue.router.immediateOnlyIfAttending)
              queueAttendingResource.get({ enterprise: profile.enterprise._id, router: $scope.selectedRouter._id, queue: $scope.selectedQueue._id, _controller: _controller }, function (data) {
                notAttending = data.notAttending
              })
          }
          // consultar las mesas si el modo es calendarizado por locación (Cuando este en multimotivo no va andar esta deteccion!!!!)
          if (reservationFactory.isMode567($scope.selectedQueue, $scope.selectedQueues))
            posTables = posTableResource.query({ router: $scope.selectedRouter._id, _controller: _controller });
          break;
        case 'slots':
          if (stepValue) $scope.reservation.slots = stepValue;
          else $scope.askForSlots = false;
          break;
        case 'additional':
          if (stepValue) {
            $scope.reservation.additionalInformation = [];
            //var additionalInformationBackUp = getAdditionalInformationBackup();
            $scope.additionalInformation.forEach(function (f) {
              if (f.type === 'CLIENT-IDENTIFICATION-NUMBER') $scope.reservation.identificationNumber = f.value;
              else if (f.type === 'CLIENT-TELEPHONE') $scope.reservation.telephone = f.value;
              else if (f.type === 'CLIENT-ADDRESS') $scope.reservation.address = f.value;
              //additionalInformationBackUp[f._id] = f.value;
            })
            //appStorage.set("additionalInformationBackUp", JSON.stringify(additionalInformationBackUp));
          } else $scope.reservation.additionalInformation = null;
          break;
        case 'reservationType':
          var queues = $scope.selectedQueue ? [$scope.selectedQueue] : $scope.selectedQueues;
          if (stepValue && !validateReservationType(stepValue, routerReservations, queues))
            return ons.notification.alert({ message: i18next.t('No se puede solicitar mas turnos ') + (stepValue === 'INMEDIATE' ? i18next.t('inmediatos') : i18next.t('futuros')), title: i18next.t('Atención') });
          if (stepValue === 'INMEDIATE') {
            $scope.reservation.inmediate = true;
          } else if (stepValue === 'FUTURE') {
            $scope.reservation.inmediate = false;
          } else {
            $scope.reservation.inmediate = null;
          }
          break;
        case 'day':
          if (stepValue && stepValue.isFullDay) $scope.reservation.waitingListDate = stepValue && stepValue.date
          $scope.selectedDay = stepValue;
          break;
        case 'date':
          if (!stepValue) $scope.selectedDate = null; // Se usa para que cuando se accede a este paso directamente, se vacie el valor y el usuario pueda volver a elegir el que quiera.
          $scope.reservation.date = stepValue && stepValue.start;
          $scope.reservation.posTable = stepValue && stepValue.posTable;
          break;
        case 'comment':
          $scope.reservation.comment = stepValue;
          break;
      }
      if (stepName) return;
      // Avanzar un paso
      var stepIx = steps.indexOf($scope.step);
      stepIx++;
      $scope.step = steps[stepIx];
      // Inicializar el paso
      switch ($scope.step) {
        case 'router':
          if ($scope.selectedQueues.length) {
            routersAvailableForQueues();
          }
          if ($scope.autoComplet) {
            nextStep(turnToModify.router)
          }
          break;
        case 'queue':
          //- Esta bandera es para turnos inmediatos con fecha especial. Feature para solicitar el proximo turno inmediato disponible
          $scope.specialImmediateDate = false;
          if (!$scope.selectedQueues.length) {
            queueResource.query({ router: $scope.reservation.router, _controller: _controller }, function (data) {
              $scope.queues = data.filter(function (f) { return f.router.target && !f.router.target.pwaChat });
              data.forEach(function (item) {
                item._routerPrice = (item.router && item.router.payConfig && item.router.payConfig.enabled) ? item.router.payConfig.price : null
                buildChilds(item, data)
              });
              $scope.queueTree = data.filter(function (f) { return !f.parent });
              //Si estoy modificando el turno completo los datos y sigo al siguiente paso
              if ($scope.autoComplet) {
                var selectedQueue = $scope.queues.find(function (f) { return f._id === turnToModify.queue })
                if (selectedQueue) nextStep(selectedQueue)
              }
              else if (data.length === 1) nextStep(data[0]);
            });
          } else { nextStep(); }
          break;
        case 'slots':
          var queues = $scope.selectedQueue ? [$scope.selectedQueue] : $scope.selectedQueues;
          var minAskForSlots = Math.min.apply(null, queues.map(function (m) { return m.askForSlots || 0; }))
          if ($scope.autoComplet) {
            nextStep(turnToModify.slots)
          }
          else {
            if (minAskForSlots) {
              $scope.askForSlots = true;
              $scope.maxSlots = [];
              for (var i = 1; i <= minAskForSlots; i++) $scope.maxSlots.push(i);
            } else {
              nextStep(1); // Si no requiere cantidad de personas se setea en 1
            }
          }
          break;
        case 'additional':
          var queues = $scope.selectedQueue ? [$scope.selectedQueue] : $scope.selectedQueues;
          $scope.additionalInformation = additionalInformations.filter(function (ai) {
            if (!ai.queues || !ai.queues.length) return true;
            return ai.queues.find(function (f) { return queues.find(function (queue) { return queue._id === f }); })
          })
          if (!$scope.additionalInformation || !$scope.additionalInformation.length) nextStep(false);
          $scope.additionalInformation.forEach(function (f) { setAdditionalInformationFromProfile(f); });
          //applyAdditionalInformationBackUp($scope.additionalInformation);
          break;
        case 'reservationType':
          routerResource.get({ _id: $scope.reservation.router, _controller: _controller }, function (data) {
            $scope.selectedRouter.reservationConfig = data.reservationConfig;
            reservationResource.query({
              router: $scope.reservation.router,
              maxDate: moment().set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 }).add(45, 'days').toDate(),
              _controller: _controller
            }, function (data) {
              routerReservations = data;
              $scope.rightNow = new Date();
              if (turnToModify) {
                // si estoy modificando turno solo contabilizo las reservas que no estoy modificando (para que se calcule bien la disponibilidad)
                routerReservations = routerReservations.filter(function (f) {
                  if (f._id !== turnToModify._id && !turnToModify._childrens.find(function (c) { return c._id === f._id })) return true
                })
              }
              const mainQueue = $scope.selectedQueue || $scope.selectedQueues[0]
              $scope.availability = reservationFactory.calculateAvailability({
                router: $scope.selectedRouter,
                mainQueue: mainQueue,
                queues: $scope.selectedQueue ? [$scope.selectedQueue] : $scope.selectedQueues,
                slots: $scope.reservation.slots || 1,
                currentReservations: routerReservations,
                holidays: holidays,
                posTables: posTables,
                maxDays: 45,
                includeFullDays: true
              });
              $scope.availablesDays = $scope.availability.filter(f => !f.isFullDay).map(function (m) { return changeUTC(m.date, $scope.selectedRouter.utcOffset, moment().format('Z').replace(':', '')) })
              $scope.fullDays = mainQueue.activeWaitingList ? $scope.availability.filter(f => f.isFullDay).map(function (m) { return changeUTC(m.date, $scope.selectedRouter.utcOffset, moment().format('Z').replace(':', '')) }) : []

              // ESTO DEBERIA SER MAS INTELIGENTE y verificar ambas queue.
              var queue = $scope.selectedQueue || $scope.selectedQueues[0];
              $scope.disableInmediaTurns = queue.disableInmediaTurns || notAttending;
              $scope.disableFutureTurns = queue.disableFutureTurns;
              // POR EL MOMENTO NO SE SOPORTA SACAR TURNOS INMEDIATOS PARA EL MULTI TURNO.
              if ($scope.selectedQueues.length) $scope.disableInmediaTurns = true;
              if ($scope.autoComplet) {
                nextStep(turnToModify.inmediate ? "INMEDIATE" : "FUTURE")
              }
              else {
                if ($scope.disableInmediaTurns) nextStep('FUTURE');
                else if ($scope.disableFutureTurns) nextStep('INMEDIATE');
              }
            });
          })
          break;
        case 'day':
          if ($scope.autoComplet) {
            if (turnToModify.date) {
              var selectedDay = $scope.availability.find(function (f) {
                if (f.date.getDay() === turnToModify.date.getDay() && f.date.getMonth() === turnToModify.date.getMonth() && f.date.getYear() === turnToModify.date.getYear())
                  return f
              })
              if (selectedDay) nextStep(selectedDay)
            }
          }
          else if ($scope.reservation.inmediate) {
            try {
              const { isCanInmediate, errorTitle, errorMessage } = canInmediate($scope.availability, $scope.reservation, $scope.selectedRouter)
              if (!isCanInmediate) {
                ons.notification.alert({ title: errorTitle, message: errorMessage })
                  .then(function () {
                    delete $scope.reservation.inmediate
                    delete $scope.reservation.date
                    setStep('queue')
                    if (!$scope.$$phase) $scope.$apply();
                  })
              } else
                nextStep($scope.availability[0]);
              if (!$scope.$$phase) $scope.$apply();
            } catch (err) {
              if (err) throw new PentaError(err)
            }
          }
          break;
        case 'date':
          if ($scope.autoComplet) {
            if (turnToModify.date) {
              var selectedHour = $scope.selectedDay.hours.find(function (f) {
                if (f.start.getHours() === turnToModify.date.getHours() && f.start.getMinutes() === turnToModify.date.getMinutes())
                  return f
              })
              if (selectedHour) nextStep(selectedHour)
            }
          }
          else if ($scope.reservation.inmediate) nextStep($scope.availability[0].hours[0]);
          break;
        case 'comment':
          if (profile.enterprise.pwaConfig && profile.enterprise.pwaConfig.turnsConfig && profile.enterprise.pwaConfig.turnsConfig.hideComment)
            nextStep('');
          break;
      }
    }

    function prevStep() {
      // Step order: 'router', 'queue', 'slots', 'reservationType', 'day', 'date', 'additional', 'comment', 'confirm'
      switch ($scope.step) {
        case 'reservationType':
          if ($scope.askForSlots) setStep('slots')
          else setStep('queue')
          break;
        case 'date':
          $scope.selectedDate = null;
          setStep('day')
          break;
        case 'additional':
          if ($scope.reservation.inmediate) setStep('reservationType')
          else setStep('date')
          break;
        case 'comment':
          if ($scope.additionalInformation && $scope.additionalInformation.length) setStep('additional')
          else if (!$scope.reservation.inmediate) setStep('date')
          else setStep('reservationType')
          break;
        default:
          var stepIndex = steps.indexOf($scope.step)
          if (stepIndex === 0) return appNavigator.popPage()
          setStep(steps[stepIndex - 1])
          break;
      }
    }

    function buildChilds(parent, nodes) {
      if (typeof parent.nodes === 'undefined') Object.defineProperty(parent, 'nodes', { writable: true });
      parent.nodes = nodes.filter(function (f) { return f.parent === parent._id });
      parent.nodes.forEach(function (node) {
        node._parentArray = parent.nodes;
      })
      parent.nodes.forEach(function (item) { buildChilds(item, nodes) });
    }

    function selectQueue(queue) {
      if (!queue) return
      if (!queue.nodes.length) {
        switch ($scope.stepMode) {
          case 'MULTI-QUEUE-FIRST':
            queue._selected = !queue._selected;
            if (queue._selected) $scope.selectedQueues.push(queue);
            else {
              var index = $scope.selectedQueues.findIndex(function (f) { return f._id === queue._id });
              if (index != -1) $scope.selectedQueues.splice(index, 1);
            }
            break;
          default:
            clearSelectedParents()
            queue._selected = true;
            return nextStep(queue)
        }
      }
      else {
        if (!queue._hide) queue._hide = true;
        else queue._hide = false;
      }
      return queue
    }

    function clearSelectedParents(nextParents) {
      var queues = nextParents || $scope.queues;
      queues.forEach(function (f) {
        if (f._selected === true) f._selected = false;
        if (!f.parent && f.parent !== f._id) f._hide = false;
        if (f.nodes && f.nodes.length) clearSelectedNodes(f.nodes)
      })
    }

    function clearSelectedNodes(nextNodes) {
      nextNodes.forEach(function (f) {
        if (f._selected === true) f._selected = false;
        if (f.nodes && f.nodes.length) clearSelectedParents(f.nodes)
      })
    }

    function modifyTurn() {
      var promesas = []
      if (turnToModify._childrens.length)
        turnToModify._childrens.forEach(function (item) {
          promesas.push(cancelReservation(item, true))
        })
      else
        promesas.push(cancelReservation(turnToModify, true))

      $q.all(promesas).then(function () {
        takeTurn()
      }).catch(function (err) { return new PentaError(err) })
    }

    function cancelReservation(turn, dontSendCancelEmail) {
      return reservationResource.cancelReservation({ _id: turn._id, dontSendCancelEmail: dontSendCancelEmail || false, _controller: _controller }, function () { }).$promise
    }

    async function takeTurn() {
      try {
        var queues = $scope.selectedQueue ? [$scope.selectedQueue] : $scope.selectedQueues;
        $scope.reservation.email = $scope.$root.profile.email;
        $scope.reservation.extraData = {};
        $scope.reservation.extraData.externalId = $scope.currentRouter.externalId;
        $scope.reservation.extraData.ticketNow = $scope.reservation.inmediate;
        //TO-DO si estoy modificando un turno tengo que cancelar el mismo y despues crear el nuevo

        // Esto permite al componente de información adicional persistir la información en el perfil del cliente
        if (typeof $scope.aiCallbacks.persistInProfile === 'function') $scope.aiCallbacks.persistInProfile();
        // Esto permite al componente de información adicional encriptar información entre otras cosas
        if (typeof $scope.aiCallbacks.additionalInformationPreSave === 'function') $scope.aiCallbacks.additionalInformationPreSave();
        let turnsConfig = profile.enterprise && profile.enterprise.pwaConfig && profile.enterprise.pwaConfig.turnsConfig;
        let err = await reservationFactory.takeTurn(queues, routerReservations, $scope.reservation, $scope.additionalInformation, turnsConfig)
        if (err && err.message === '7005') return setStep('router');
        // persistInProfile es una funcion que entrega el componente de información adicional, pueden encontrarlo en additionalInformation.subview.jade
        finishReservation();
        /////
        $scope.$applyAsync();
      }
      catch (err) {
        $scope.$applyAsync(() => { throw err });
      }
    }

    async function joinWaitlist() {
      try {
        if (!$scope.reservation) throw new PentaError('No se puede operar sin una reserva')
        if (!$scope.reservation.waitingListDate || !($scope.reservation.waitingListDate instanceof Date))
          throw new PentaError('La fecha ingresada no es un formato valido')
        if (!$scope.reservation.router) throw new PentaError('No se puede operar sin una Sucursal')
        if (!$scope.reservation.queue) throw new PentaError('No se puede operar sin un Motivo')
        let body = {
          router: $scope.reservation.router,
          queue: $scope.reservation.queue,
          date: moment($scope.reservation.waitingListDate).utcOffset("0000", true).startOf('day'),
          comment: $scope.reservation.waitingListComment
        }
        let waitingList = await waitingListResource.save(body).$promise
        joinWaitlistModal.open({ waitingList: waitingList })
          .then(function () { finishReservation(true) })
          .catch(function (error) { console.log(error); finishReservation(true) })
        $scope.$applyAsync();
      }
      catch (err) { $scope.$applyAsync(() => { throw err }); }
    }

    function finishReservation(dontOpenRateModal) {
      onClose();
      appNavigator.popPage()
      $timeout(function () {
        if (!dontOpenRateModal) appRateModal.open('newTurn')
      }, 7000)
    }

    function validateReservationType(stepValue, myReservations, queues) {
      if (!myReservations || !myReservations.length) return true;
      return queues.every(function (queue) {
        var count = 0;
        if (stepValue === 'INMEDIATE') {
          if (!queue.maxInmediaTurnsPerUser) return true;
          count = myReservations.reduce(function (total, current) {
            if (current.client && current.inmediate && (current.queue === queue._id)) total += 1;
            return total;
          }, 0)
          if (count < queue.maxInmediaTurnsPerUser) return true;
        }
        if (stepValue === 'FUTURE') {
          if (!queue.maxFutureTurnsPerUser) return true;
          count = myReservations.reduce(function (total, current) {
            if (current.client && !current.inmediate && (current.queue === queue._id)) total += 1
            return total;
          }, 0)
          if (count < queue.maxFutureTurnsPerUser) return true;
        }
        return false;
      })
    }

    function getSelectedQueuesNames() {
      var text = '';
      if (!$scope.selectedQueues.length) return '';
      $scope.selectedQueues.forEach(function (f, i) {
        if (f.name) text += f.name + ($scope.selectedQueues.length > 1 && $scope.selectedQueues.length !== (i + 1) ? ', ' : '');
      })
      return text
    }

    function routersAvailableForQueues() {
      $scope.routers = allRouters;
      $scope.routers = $scope.routers.filter(function (router) {
        return $scope.selectedQueues.every(function (f) { return f.routers.find(function (r) { return r._id === router._id }) });
      })
    }

    function setRoutersDistance(position, routers) {
      if (!position || !position.latitude || !position.longitude) return
      var routersFiltered = getRoutersWithCoordinates(routers)
      routersFiltered.forEach(function (router) {
        var routerPosition = router.geolocalization.coordinates;
        router._distanceToUser = geolocationFactory.getDistance(routerPosition[0], routerPosition[1], position.latitude, position.longitude)
      });
      $scope.routersDistanceReady = true;
      if (!$scope.$$phase) $scope.$apply();
    }

    function getRoutersWithCoordinates(routers) {
      if (!routers) return []
      return routers.filter(function (router) {
        if (!router.geolocalization || !router.geolocalization.coordinates) return;
        if (!router.geolocalization.coordinates[0] || !router.geolocalization.coordinates[1]) return;
        var lat = router.geolocalization.coordinates[0];
        var lng = router.geolocalization.coordinates[1];
        if (!(lat > -90 && lat < 90) || !(lng > -180 && lng < 180)) return;
        return true;
      })
    }

    function filterRoutersByDistance(routers) {
      return new Promise(function (resolve, reject) {
        if (!$scope.maxRouterDistance) return resolve(routers)
        if (turnToModify) {
          var turnRouter = routers.find(function (router) { return router._id === turnToModify.router })
          return resolve(filterFromRouterPosition(turnRouter, routers))
        }
        getUserPosition(routers)
          .then(function (userPosition) {
            setRoutersDistance(userPosition, routers);
            var limitedRouters = routers.filter(function (router) {
              return router._distanceToUser < $scope.maxRouterDistance
            })
            $scope.userPosition = userPosition
            resolve(limitedRouters)
          })
          .catch(function () {
            resolve(routers)
          })
      })
    }

    function filterFromRouterPosition(router, routers) {
      setRoutersDistance({ latitude: router.geolocalization.coordinates[0], longitude: router.geolocalization.coordinates[1] }, routers)
      var limitedRouters = routers.filter(function (router) {
        return router._distanceToUser < $scope.maxRouterDistance
      })
      return limitedRouters
    }

    // Esta función intenta obtener la posición por GPS y si no lo consigue la pregunta manualmente en un popup.
    function getUserPosition(routers) {

      return $q(function (resolve, reject) {
        // Busca ubicación por GPS
        geolocationFactory.getCurrentPosition(
          function (userPosition) {
            resolve(userPosition)
          },
          function () { // Error callback
            geolocationFactory.estimatePosition()
              .then(function (estimatedPosition) {
                askForPosition(estimatedPosition, routers).then(resolve).catch(reject)
              })
              .catch(function () {
                askForPosition(null, routers).then(resolve).catch(reject)
              })
          })
      })

    }

    function askForPosition(estimated, routers) {
      return $q(function (resolve, reject) {
        if (!estimated) estimated = routersPositionAverage(routers)
        manualGeolocationModal.open({ latitude: estimated.latitude, longitude: estimated.longitude, routers: routers })
          .then(function (position) {
            $scope.badPrecision = true
            resolve(position)
          })
          .catch(function (error) { reject(error) })
      })
    }

    function routersPositionAverage(routers) {
      if (!routers) return { latitude: -34.60557434961402, longitude: -58.38013836941472 }
      var filteredRouters = getRoutersWithCoordinates(routers)
      var positionSum = filteredRouters.reduce(function (acc, router) {
        acc.latitude += router.geolocalization.coordinates[0]
        acc.longitude += router.geolocalization.coordinates[1]
        return acc
      }, { latitude: 0, longitude: 0 })
      return {
        latitude: positionSum.latitude / filteredRouters.length,
        longitude: positionSum.longitude / filteredRouters.length,
      }
    }

    function limitRoutersOnManualPosition(routers, previousPosition) {
      askForPosition(previousPosition, routers)
        .then(function (userPosition) {
          if (!userPosition) return
          setRoutersDistance(userPosition, routers)
          var limitedRouters = routers.filter(function (router) {
            return router._distanceToUser < $scope.maxRouterDistance
          })
          displayLocation(userPosition.latitude, userPosition.longitude).then(function (humanizedPosition) {
            $scope.humanizedPosition = humanizedPosition
          }).catch(function (err) { throw new PentaError(err) })
          $scope.userPosition = userPosition
          if (limitedRouters.length) $scope.outOfRange = false;
          $scope.routers = limitedRouters
          allRouters = limitedRouters
        })
        .catch(function (err) { if (err) throw new PentaError(err) })
    }

    function displayLocation(latitude, longitude) {
      return $q(function (resolve, reject) {
        if (!latitude || !longitude) reject(i18next.t('La ubicación otorgada es inválida.'))
        var geocoder;
        geocoder = new google.maps.Geocoder();
        var latlng = new google.maps.LatLng(latitude, longitude);

        geocoder.geocode(
          { 'latLng': latlng },
          function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                var add = results[0].formatted_address;
                resolve(add)
              }
              else {
                reject(i18next.t('Ubicación no conseguida'));
              }
            }
            else {
              reject(i18next.t('La localización falló. Estado: ') + status)
            }
          }
        );
      })
    }

    function getAdditionalInformationBackup() {
      var additionalInformationBackUp = appStorage.get("additionalInformationBackUp");
      if (additionalInformationBackUp) {
        try { additionalInformationBackUp = JSON.parse(additionalInformationBackUp); } catch (error) { additionalInformationBackUp = {}; }
      } else additionalInformationBackUp = {};
      return additionalInformationBackUp;
    }

    function applyAdditionalInformationBackUp(additionalInformations) {
      if (!additionalInformations || !additionalInformations.length) return;
      var additionalInformationBackUp = getAdditionalInformationBackup();
      additionalInformations.forEach(function (f) {
        f.value = additionalInformationBackUp[f._id];
      })
    }

    function setAdditionalInformationFromProfile(additionalInformation) {
      if (!additionalInformation || !additionalInformation._id) return
      var aiProfile = additionalInformationProfile.find(function (f) { return f._id === additionalInformation._id });
      if (aiProfile) additionalInformation.value = aiProfile.value;
    }

    function dateToAvailability() {
      removeWaitingListFromSteper()
      $scope.onWaitingList = false
      if (!$scope.selectedDate) throw new PentaError('No se especifico fecha para calcular la fecha habilitada')
      let result = $scope.availability.find(function (f) { return f.date.toString() === changeUTC($scope.selectedDate, moment().format('Z').replace(':', ''), $scope.selectedRouter.utcOffset).toString() })
      if (!result) throw new PentaError('La fecha seleccionada no se encontró en las fechas habilitadas')
      if (result.isFullDay && $scope.selectedQueue?.activeWaitingList) processWaitingList(result)
      else return nextStep(result)
    }

    function processWaitingList(availability) {
      addWaitingListFromSteper()
      let selectedDate = moment(changeUTC($scope.selectedDate, moment().format('Z').replace(':', ''), $scope.selectedRouter.utcOffset)).startOf('day').valueOf()
      let waitingList = $scope.myWaitingList.find(f => {
        if(!f.date || !f.queue || !f.router) return false
        let date = moment(changeUTC(f.date, moment().format('Z').replace(':', ''), $scope.selectedRouter.utcOffset)).startOf('day').valueOf()
        // Tiene que ser para la misma fecha, la misma sucursal y el mismo motivo
        return date === selectedDate && f.router === $scope.selectedRouter?._id && f.queue === $scope.selectedQueue?._id
      })
      noTurnsAvailableModal.open({ waitingList: waitingList })
        .then(function (data) {
          if (!data && $scope.selectedDate) return $scope.selectedDate = undefined
          if (data) {
            nextStep(availability, 'day')
            setStep('waitingList')
          }
        })
        .catch(function (error) { console.log(error) })
    }

    function addWaitingListFromSteper() {
      if (steps.some(f => f === 'waitingList')) return
      steps.push('waitingList');
    }

    function removeWaitingListFromSteper() {
      let index = steps.findIndex(f => f === 'waitingList')
      if (index !== -1) steps.splice(index, 1);
    }

    async function getMyWaitingList() {
      try {
        if ($scope.myWaitingList.length) return // Si ya llene mi lista de espera no la obtengo nuevamente
        $scope.myWaitingList = await waitingListResource.query({ _select: ['router', 'queue', 'date'] }).$promise
        $scope.$applyAsync();
      }
      catch (err) { $scope.$applyAsync(() => { throw err }); }
    }

    function changeUTC(date, from, to) {
      if (!date || !from || !to) return
      if (!date.utcOffset) date = moment(date)
      if (date._isValid)
        return date.utcOffset(from).utcOffset(to, true).toDate()
    }

    function canInmediate(availability, reservation, router) {
      // Si no tengo Disponibilidad luego del calculateAvailability, devuelve un error de no estamos atendiendo
      if (!availability?.length || !router?.utcOffset)
        return {
          isCanInmediate: false,
          errorTitle: i18next.t('En este momento no estamos atendiendo.'),
          errorMessage: i18next.t('Inténtelo de nuevo dentro del horario de atención.')
        }
      const now = moment().utcOffset(router.utcOffset)
      const startOfDay = now.clone().utcOffset(router.utcOffset).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
      const availabilityDay = availability[0]
      reservation.date = now
      // Si el primer dia disponible es distinto a hoy, devuelve un error de no estamos atendiendo
      if (!startOfDay.isSame(availabilityDay.date)) {
        return {
          isCanInmediate: false,
          errorTitle: i18next.t('En este momento no estamos atendiendo.'),
          errorMessage: i18next.t('Inténtelo de nuevo dentro del horario de atención.')
        }
        // Si no tengo disponibilidad ahora mismo, devuelve un error de nuestros agentes están ocupados
      } else if (!availabilityRightNow(now, availabilityDay)) {
        return {
          isCanInmediate: false,
          errorTitle: i18next.t('En este momento todos nuestros agentes están ocupados.'),
          errorMessage: i18next.t('Inténtelo nuevamente más tarde.')
        }
      } else return {
        isCanInmediate: true,
        errorTitle: null,
        errorMessage: null
      }
    }

    //- Esta function devuelve true/false si hay disponibilidad ahora.
    function availabilityRightNow(now, availabilityDay, reservation, enterprise, slots) {
      if (!now || !availabilityDay) return false
      if (availabilityDay.isFullDay) return false
      if (!availabilityDay.hours?.length) return false
      return true
    }
  }
})();

